import axios from "axios";

axios.defaults.baseURL = "https://xn--22-6kcp5d.xn--p1ai/api/1/";
// axios.defaults.baseURL = "http://109.111.185.150:9053/api/1/";
// axios.defaults.baseURL ="https://2b89-103-45-246-79.ngrok-free.app/api/1";

axios.interceptors.request.use((config) => {
  console.log(config);
  return config;
});

export default axios;

import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";
import BlogMainDoctor from '@/views/About/BlogMainDoctor.vue';
import Management from '@/views/About/Management.vue';
import ArticleDetail from '@/views/ArticleDetail.vue';
import About from '@/views/About/About.vue';
import Doctors from '@/views/About/Doctors.vue';
import GeneralInfo from '@/views/About/GeneralInfo.vue';
import Schedule from '@/views/About/Schedule.vue';
import Contact from '@/views/About/Contact.vue';
import News from '@/views/About/News.vue';
import Documents from '@/views/About/Documents.vue';
import Vacancies from '@/views/About/Vacancies.vue';
import Reviews from '@/views/About/Reviews.vue';
import Department from '@/views/Department/Department.vue';
import Policlinika from '@/views/Department/Policlinika.vue';
import AkusherskijStacioner from '@/views/Department/AkusherskijStacioner.vue';
import UnderDepartment from '@/views/Department/UnderDepartment.vue';
import Neonatology from '@/views/Department/Neonatology.vue';
import Gynecology from '@/views/Department/Gynecology.vue';
import Laboratory from '@/views/Department/Laboratory.vue';
import Catamnesis from '@/views/Department/Catamnesis.vue';
import SupportCenter from '@/views/Department/SupportCenter.vue';
import AuxiliaryServices from '@/views/Department/AuxiliaryServices.vue';
import ECO from '@/views/ECO/ECO.vue';
import Article from '@/views/Department/Article.vue';
import Patients from '@/views/Patients/Patients.vue'
import ServicesOMS from '@/views/Patients/ServicesOMS.vue'
import Diagnostics from '@/views/ECO/Diagnostics.vue'
import Donation from '@/views/ECO/Donation.vue'
import InfertilityTreatment from '@/views/ECO/InfertilityTreatment.vue'
import Surrogacy from '@/views/ECO/Surrogacy.vue'
import ECOGirls from '@/views/ECO/ECOGirls.vue'
import ECOBoys from '@/views/ECO/ECOBoys.vue'
import Childbirth from '@/views/Childbirth/Childbirth.vue'
import PersonalDoctors from '@/views/Childbirth/PersonalDoctors.vue'
import PersonalAkysherka from '@/views/Childbirth/PersonalAkysherka.vue'
import Pregnancy from '@/views/Pregnancy.vue'
import Newborn from '@/views/Newborn.vue'
import Services from '@/views/Services.vue'
import ArticleAll from '@/views/ArticleAll.vue'
import RecordingRules from '@/views/Patients/RecordingRules.vue'
import Tests from '@/views/Patients/Tests.vue'
import Hospitalization from '@/views/Patients/Hospitalization.vue'
import Foreigners from '@/views/Patients/Foreigners.vue'
import Refund from '@/views/Patients/Refund.vue'
import SchedulePatients from '@/views/Patients/SchedulePatients.vue'
import ListProduct from '@/views/Patients/ListProduct.vue'
import PrayerRoom from '@/views/Patients/PrayerRoom.vue'
import MedicalTourism from '@/views/Patients/MedicalTourism.vue'
import MedicalTourismEnglish from '@/views/Patients/MedicalTourismEnglish.vue'
import Gallery from '@/views/About/Gallery.vue'
import Volunteering from '@/views/Education/Volunteering.vue'
import PaidServices from '@/views/PaidServices/PaidServices.vue'
import SchoolOfParents from '@/views/Education/SchoolOfParents.vue'
import DocumentInstitution from '@/views/Documents/DocumentInstitution.vue'
import ItemFolder from '@/views/Documents/ItemFolder.vue'
import DocumentsPatients from '@/views/Documents/DocumentsPatients.vue'
import DocumentsPaidServices from '@/views/Documents/DocumentsPaidServices.vue'
import DocumentsAntiCorruption from '@/views/Documents/DocumentsAntiCorruption.vue'
import DocumentsEducation from '@/views/Documents/DocumentsEducation.vue'
import Education from '@/views/Education/Education.vue'
import Appeal from '@/views/Appeal.vue'
import NotFound from '@/views/NotFound.vue'
import NewsDetail from '@/views/NewsDetail.vue'
import ConsentPage from '@/views/ConsentPage.vue'
import DocumentSpecial from '@/views/Documents/DocumentSpecial.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
    meta: {
      breadcrumbs: ['Главная'],
      title: "Главная",
      description: "Добро пожаловать на главную страницу центра."
    }
  },
  {
    path: "/about",
    name: "About",
    component: About,
    meta: {
      breadcrumbs: ['Главная', 'О центре'],
      title: "О центре",
      description: "Информация о центре и его миссии."
    }
  },
  {
    path: "/about/specialists",
    name: "Doctors",
    component: Doctors,
    meta: {
      breadcrumbs: ['Главная', 'О центре', 'Специалисты'],
      title: "Специалисты",
      description: "Список специалистов центра и их квалификации."
    }
  },
  {
    path: "/about/general-info",
    name: "GeneralInfo",
    component: GeneralInfo,
    meta: {
      breadcrumbs: ['Главная', 'О центре', 'Общие сведения'],
      title: "Общие сведения",
      description: "Основная информация о центре."
    }
  },
  {
    path: "/about/schedule",
    name: "Schedule",
    component: Schedule,
    meta: {
      breadcrumbs: ['Главная', 'О центре', 'Режим работы'],
      title: "Режим работы",
      description: "График работы центра."
    }
  },
  {
    path: "/about/contacts",
    name: "Contact",
    component: Contact,
    meta: {
      breadcrumbs: ['Главная', 'О центре', 'Контакты'],
      title: "Контакты",
      description: "Как связаться с нами."
    }
  },
  {
    path: "/about/news",
    name: "News",
    component: News,
    meta: {
      breadcrumbs: ['Главная', 'О центре', 'Новости'],
      title: "Новости",
      description: "Последние новости и обновления центра."
    }
  },
  {
    path: "/about/news/:id",
    name: "NewsItem",
    component: NewsDetail,
    meta: {
      breadcrumbs: ['Главная', 'О центре', 'Новости', ':id'],

    }
  },
  {
    path: "/documents",
    name: "Documents",
    component: Documents,
    meta: {
      breadcrumbs: ['Главная', 'Документы'],
      title: "Документы",
      description: "Документы и регламенты центра."
    }
  },
  {
    path: "/about/vacancies",
    name: "Vacancies",
    component: Vacancies,
    meta: {
      breadcrumbs: ['Главная', 'О центре', 'Вакансии'],
      title: "Вакансии",
      description: "Открытые вакансии в центре."
    }
  },
  {
    path: "/about/reviews",
    name: "Reviews",
    component: Reviews,
    meta: {
      breadcrumbs: ['Главная', 'О центре', 'Отзывы'],
      title: "Отзывы",
      description: "Отзывы пациентов и их семей."
    }
  },
  {
    path: "/about/gallery",
    name: "Gallery",
    component: Gallery,
    meta: {
      breadcrumbs: ['Главная', 'О центре', 'Галерея'],
      title: "Галерея",
      description: "Фотографии и галерея центра."
    }
  },
  {
    path: "/about/blog",
    name: "BlogMainDoctor",
    component: BlogMainDoctor,
    meta: {
      breadcrumbs: ['Главная', 'О центре', 'Блог главного врача'],
      title: "Блог главного врача",
      description: "Блог и статьи от главного врача центра."
    }
  },
  {
    path: "/about/blog/:id",
    name: "ArticleDetail",
    component: ArticleDetail,
    props: true,
    meta: {
      breadcrumbs: ['Главная', 'О центре', 'Блог главного врача', ':id'],

    }
  },
  {
    path: "/about/management",
    name: "Management",
    component: Management,
    meta: {
      breadcrumbs: ['Главная', 'О центре', 'Руководство'],
      title: "Руководство",
      description: "Информация о руководстве центра."
    }
  },
  {
    path: "/department",
    name: "Department",
    component: Department,
    meta: {
      breadcrumbs: ['Главная', 'Отделения'],
      title: "Отделения",
      description: "Информация о всех отделениях центра."
    }
  },
  {
    path: "/department/policlinika",
    name: "Поликлиника",
    component: Policlinika,
    meta: {
      breadcrumbs: ['Главная', 'Отделения', 'Поликлиника'],
      title: "Поликлиника",
      description: "Поликлиническое отделение центра."
    }
  },
  {
    path: "/department/akusherskij-stacionar",
    name: "Акушерский стационар",
    component: AkusherskijStacioner,
    meta: {
      breadcrumbs: ['Главная', 'Отделения', 'Акушерский стационар'],
      title: "Акушерский стационар",
      description: "Отделение акушерского стационара центра."
    }
  },
  {
    path: "/department/neonatologicheskij-stacionar",
    name: "Неонатологический стационар",
    component: Neonatology,
    meta: {
      breadcrumbs: ['Главная', 'Отделения', 'Неонатологический стационар'],
      title: "Неонатологический стационар",
      description: "Отделение неонатологии для новорожденных пациентов."
    }
  },
  {
    path: "/department/centr-ginekologii",
    name: "Центр гинекологии",
    component: Gynecology,
    meta: {
      breadcrumbs: ['Главная', 'Отделения', 'Центр гинекологии'],
      title: "Центр гинекологии",
      description: "Центр, предоставляющий гинекологические услуги."
    }
  },
  {
    path: "/department/laboratornye-i-instrumentalnye-metody-obsledovaniya",
    name: "Лабораторные обследования",
    component: Laboratory,
    meta: {
      breadcrumbs: ['Главная', 'Отделения', 'Лабораторные обследования'],
      title: "Лабораторные обследования",
      description: "Отделение лабораторной диагностики."
    }
  },
  {
    path: "/department/kabinet-katamnesticheskogo-nablyudeniya-za-detmi-s-perinatalnoj-patologiej",
    name: "Отделение катамнестического наблюдения",
    component: Catamnesis,
    meta: {
      breadcrumbs: ['Главная', 'Отделения', 'Отделение катамнестического наблюдения'],
      title: "Отделение катамнестического наблюдения",
      description: "Катамнестическое наблюдение за детьми с перинатальной патологией."
    }
  },
  {
    path: "/department/support-center",
    name: "Центр поддержки",
    component: SupportCenter,
    meta: {
      breadcrumbs: ['Главная', 'Отделения', 'Центр поддержки'],
      title: "Центр поддержки",
      description: "Центр поддержки пациентов и их семей."
    }
  },
  {
    path: "/department/vspomogatelnye-sluzhby",
    name: "Вспомогательные службы",
    component: AuxiliaryServices,
    meta: {
      breadcrumbs: ['Главная', 'Отделения', 'Вспомогательные службы'],
      title: "Вспомогательные службы",
      description: "Дополнительные вспомогательные службы центра."
    }
  },
  {
    path: "/family-center",
    name: "ЭКО",
    component: ECO,
    meta: {
      breadcrumbs: ['Главная', 'ЭКО'],
      title: "ЭКО",
      description: "Центр репродуктивной медицины и ЭКО."
    }
  },
  {
    path: "/family-center/" + encodeURI("Диагностика причин бесплодия"),
    name: "Diagnostics",
    component: Diagnostics,
    meta: {
      breadcrumbs: ['Главная', 'ЭКО', 'Диагностика причин бесплодия'],
      title: "Диагностика бесплодия",
      description: "Обследование и диагностика причин бесплодия."
    }
  },
  {
    path: "/family-center/" + encodeURI("Стать донором"),
    name: "Donation",
    component: Donation,
    meta: {
      breadcrumbs: ['Главная', 'ЭКО', 'Стать донором'],
      title: "Донорство",
      description: "Программа донорства яйцеклеток и спермы."
    }
  },
  {
    path: "/family-center/" + encodeURI("Стать донором") + '/' + encodeURI("ЭКО с донорскими яйцеклетками"),
    name: "ECOGirls",
    component: ECOGirls,
    meta: {
      breadcrumbs: ['Главная', 'ЭКО', 'Стать донором', 'ЭКО с донорскими яйцеклетками'],
      title: "ЭКО с донорскими яйцеклетками",
      description: "ЭКО с донорскими яйцеклетками"
    }
  },
  {
    path: "/family-center/" + encodeURI("Стать донором") + '/' + encodeURI("ЭКО с донорской спермой"),
    name: "ECOBoys",
    component: ECOBoys,
    meta: {
      breadcrumbs: ['Главная', 'ЭКО', 'Стать донором', 'ЭКО с донорской спермой'],
      title: "ЭКО с донорской спермой",
      description: "ЭКО с донорской спермой"
    }
  },
  {
    path: "/family-center/" + encodeURI("Лечение бесплодия"),
    name: "InfertilityTreatment",
    component: InfertilityTreatment,
    meta: {
      breadcrumbs: ['Главная', 'ЭКО', 'Лечение бесплодия'],
      title: "Лечение бесплодия",
      description: "Методы и программы лечения бесплодия."
    }
  },
  {
    path: "/family-center/" + encodeURI("Суррогатное материнство"),
    name: "Surrogacy",
    component: Surrogacy,
    meta: {
      breadcrumbs: ['Главная', 'ЭКО', 'Суррогатное материнство'],
      title: "Суррогатное материнство",
      description: "Программа суррогатного материнства и репродуктивной помощи."
    }
  },
  {
    path: "/pregnancy",
    name: "Pregnancy",
    component: Pregnancy,
    meta: {
      breadcrumbs: ['Главная', 'Беременность'],
      title: "Беременность",
      description: "Информация и услуги для беременных женщин."
    }
  },
  {
    path: "/newborn",
    name: "Newborn",
    component: Newborn,
    meta: {
      breadcrumbs: ['Главная', 'Плод и новорожденный'],
      title: "Плод и новорожденный",
      description: "Уход и медицинская помощь для новорожденных."
    }
  },
  {
    path: "/childbirth",
    name: "Childbirth",
    component: Childbirth,
    meta: {
      breadcrumbs: ['Главная', 'Роды'],
      title: "Роды",
      description: "Информация о родах и поддержке."
    }
  },
  {
    path: "/services",
    name: "Services",
    component: Services,
    meta: {
      breadcrumbs: ['Главная', 'Услуги'],
      title: "Услуги",
      description: "Все услуги, предоставляемые нашим центром."
    }
  },
  {
    path: "/childbirth/" + encodeURI("Персональный врач на роды"),
    name: "PersonalDoctors",
    component: PersonalDoctors,
    meta: {
      breadcrumbs: ['Главная', 'Роды', 'Персональный врач на роды'],
      title: "Персональный врач на роды",
      description: "Услуга персонального врача на родах."
    }
  },
  {
    path: "/childbirth/" + encodeURI("Персональная акушерка на роды"),
    name: "PersonalAkysherka",
    component: PersonalAkysherka,
    meta: {
      breadcrumbs: ['Главная', 'Роды', 'Персональная акушерка на роды'],
      title: "Персональная акушерка на роды",
      description: "Услуга персональной акушерки на родах."
    }
  },
  {
    path: "/department/:nameDepartment/:name",
    name: "UnderDepartment",
    component: UnderDepartment,
    meta: {
      breadcrumbs: ['Главная', 'Отделения', ':nameDepartment', ':name'],
      title: "Подотделение",
      description: "Информация о конкретном подотделении."
    }
  },
  {
    path: "/:nameDepartment/article/:id",
    name: "ArticleAll",
    component: ArticleAll,
    meta: {
      breadcrumbs: ['Главная', ':nameDepartment', 'Статья:', ':id'],
    }
  },
  {
    path: "/department/:nameDepartment/article/:id",
    name: "Article",
    component: Article,
    meta: {
      breadcrumbs: ['Главная', 'Отделения', ':nameDepartment', 'Статья:', ':id'],

    }
  },
  {
    path: "/patients",
    name: "Patients",
    component: Patients,
    meta: {
      breadcrumbs: ['Главная', 'Пациентам'],
      title: "Пациентам",
      description: "Полезная информация для пациентов центра."
    }
  },
  {
    path: "/oms",
    name: "Услуги ОМС",
    component: ServicesOMS,
    meta: {
      breadcrumbs: ['Главная', 'Пациентам', 'Услуги ОМС'],
      title: "Услуги ОМС",
      description: "Информация о предоставляемых услугах по ОМС."
    }
  },
  {
    path: "/rules",
    name: "Правила записи на прием",
    component: RecordingRules,
    meta: {
      breadcrumbs: ['Главная', 'Пациентам', 'Правила записи на прием'],
      title: "Правила записи на прием",
      description: "Условия и правила записи на прием."
    }
  },
  {
    path: "/tests",
    name: "Анализы",
    component: Tests,
    meta: {
      breadcrumbs: ['Главная', 'Пациентам', 'Анализы'],
      title: "Анализы",
      description: "Информация об анализах, доступных в центре."
    }
  },
  {
    path: "/hospitalization",
    name: "Госпитализация",
    component: Hospitalization,
    meta: {
      breadcrumbs: ['Главная', 'Пациентам', 'Госпитализация'],
      title: "Госпитализация",
      description: "Условия и процедура госпитализации."
    }
  },
  {
    path: "/foreigners",
    name: "Иностранным гражданам",
    component: Foreigners,
    meta: {
      breadcrumbs: ['Главная', 'Пациентам', 'Иностранным гражданам'],
      title: "Иностранным гражданам",
      description: "Медицинские услуги для иностранных граждан."
    }
  },
  {
    path: "/tax-refund",
    name: "Справка для налогового вычета",
    component: Refund,
    meta: {
      breadcrumbs: ['Главная', 'Пациентам', 'Справка для налогового вычета'],
      title: "Справка для налогового вычета",
      description: "Получение справки для налогового вычета на лечение."
    }
  },
  {
    path: "/schedule",
    name: "График приема граждан",
    component: SchedulePatients,
    meta: {
      breadcrumbs: ['Главная', 'Пациентам', 'График приема граждан'],
      title: "График приема граждан",
      description: "Расписание приема граждан в центре."
    }
  },
  {
    path: "/list-product",
    name: "Список продуктов для передачи",
    component: ListProduct,
    meta: {
      breadcrumbs: ['Главная', 'Пациентам', 'Список продуктов для передачи'],
      title: "Список продуктов для передачи",
      description: "Рекомендованный список продуктов для передачи пациентам."
    }
  },
  {
    path: "/prayer-room",
    name: "Молитвенная комната",
    component: PrayerRoom,
    meta: {
      breadcrumbs: ['Главная', 'Пациентам', 'Молитвенная комната'],
      title: "Молитвенная комната",
      description: "Молитвенная комната для посетителей центра."
    }
  },
  {
    path: "/medical-tourism",
    name: "Медицинский туризм",
    component: MedicalTourism,
    meta: {
      breadcrumbs: ['Главная', 'Пациентам', 'Медицинский туризм'],
      title: "Медицинский туризм",
      description: "Услуги медицинского туризма для иностранных пациентов."
    }
  },
  {
    path: "/medical-tourism-english",
    name: "Медицинский туризм English",
    component: MedicalTourismEnglish,
    meta: {
      breadcrumbs: ['Главная', 'Пациентам', 'Медицинский туризм English'],
      title: "Medical Tourism",
      description: "Medical tourism services for foreign patients."
    }
  },
  {
    path: "/education/volunteer-activities",
    name: "Волонтерство",
    component: Volunteering,
    meta: {
      breadcrumbs: ['Главная', 'Образование', 'Волонтерство'],
      title: "Волонтерство",
      description: "Волонтерские программы в центре."
    }
  },
  {
    path: "/paid-services",
    name: "Платные услуги",
    component: PaidServices,
    meta: {
      breadcrumbs: ['Главная', 'Платные услуги'],
      title: "Платные услуги",
      description: "Все платные услуги, предоставляемые центром."
    }
  },
  {
    path: "/education/school-of-parents",
    name: "Школа будущих родителей",
    component: SchoolOfParents,
    meta: {
      breadcrumbs: ['Главная', 'Образование', 'Школа будущих родителей'],
      title: "Школа будущих родителей",
      description: "Образовательная программа для будущих родителей."
    }
  },
  {
    path: "/documents/institution",
    name: "Документы учреждения",
    component: DocumentInstitution,
    meta: {
      breadcrumbs: ['Главная', 'Документы', 'Документы учреждения'],
      title: "Документы учреждения",
      description: "Официальные документы и лицензии центра."
    }
  },
  {
    path: "/documents/education",
    name: "Образование",
    component: DocumentsEducation,
    meta: {
      breadcrumbs: ['Главная', 'Документы', 'Образование'],
      title: "Документы по образованию",
      description: "Документы об образовательных программах."
    }
  },
  {
    path: "/documents/patients",
    name: "Документы пациентам",
    component: DocumentsPatients,
    meta: {
      breadcrumbs: ['Главная', 'Документы', 'Пациентам'],
      title: "Документы пациентам",
      description: "Необходимые документы для пациентов центра."
    }
  },
  {
    path: "/documents/paid-services",
    name: "Документы платные услуги",
    component: DocumentsPaidServices,
    meta: {
      breadcrumbs: ['Главная', 'Документы', 'Платные услуги'],
      title: "Документы платные услуги",
      description: "Документы для предоставления платных услуг."
    }
  },
  {
    path: "/documents/anti-corruption",
    name: "Противодействие коррупции",
    component: DocumentsAntiCorruption,
    meta: {
      breadcrumbs: ['Главная', 'Документы', 'Противодействие коррупции'],
      title: "Противодействие коррупции",
      description: "Меры по противодействию коррупции в центре."
    }
  },
  {
    path: "/documents/special-assessment",
    name: "Специальная оценка условий труда",
    component: DocumentSpecial,
    meta: {
      breadcrumbs: ['Главная', 'Документы', 'Специальная оценка условий труда'],
      title: "Специальная оценка условий труда",
      description: "Специальная оценка условий труда."
    }
  },
  {
    path: "/documents/:nameFolder/:name",
    name: "FolderDetails",
    component: ItemFolder,
    meta: {
      breadcrumbs: ['Главная', 'Документы', ':nameFolder', ':name'],
      title: "Подробности папки",
      description: "Просмотр содержимого выбранной папки."
    }
  },
  {
    path: "/documents/:nameFolder/:name/:nextName",
    name: "NextFolderDetails",
    component: ItemFolder,
    meta: {
      breadcrumbs: ['Главная', 'Документы', ':nameFolder', ':name', ':nextName'],
      title: "Детали подкаталога",
      description: "Просмотр содержимого выбранного подкаталога."
    }
  },
  {
    path: "/education",
    name: "Education",
    component: Education,
    meta: {
      breadcrumbs: ['Главная', 'Образование'],
      title: "Образование",
      description: "Образовательные программы и курсы центра."
    }
  },
  {
    path: "/appeal",
    name: "Appeal",
    component: Appeal,
    meta: {
      breadcrumbs: ['Главная', 'Написать обращение'],
      title: "Обращение",
      description: "Форма для отправки обращения в центр."
    }
  },
  {
    path: "/appeal/consent",
    name: "Consent",
    component: ConsentPage,
    meta: {
      breadcrumbs: ['Главная', 'Написать обращение', 'Согласие на обработку данных'],
      title: "Согласие на обработку данных",
      description: "Согласие на обработку персональных данных."
    }
  },

  // appeal
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    meta: {
      breadcrumbs: ['Главная', 'Не найдено']
    }
  }

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
